/* eslint-disable */
import React from "react"
import { graphql, Link, navigate } from "gatsby"
import ReactPaginate from "react-paginate"
import SEO from '../components/SEO'
import { SimpleGrid, Box, Heading, Button } from '@chakra-ui/react'
import Layout from "../components/Layout"
import { normalizePath } from "../utils/get-url-path"
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import pageTheme from '../gatsby-plugin-chakra-ui/theme'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import themeVars from "../gatsby-plugin-chakra-ui/theme-vars"
import parse from 'html-react-parser'

const theme = extendTheme({ ...pageTheme })

export default ({ data, pageContext }) => {
  const { mtaGeneralSettings } = data.wp
  const heroContent = mtaGeneralSettings.blogHeroContent
  // Breadcrumbs
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const crumbsUnslugged = crumbs.map((crumb) => { // Unsluggify breadcrumbs
    return {
      pathname: crumb.pathname,
      crumbLabel: crumb.crumbLabel.replace(/\-/g, ' ').replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() })
    }
  })


  return (
    <ChakraProvider theme={theme}>
      <SEO title="News & Alerts" />
      <Layout heroContent={heroContent}>
        <Box className="container" mt={100} maxWidth="1200px">
          {crumbs.length > 1 &&
          <Box mb="100px">
            <Breadcrumb crumbs={crumbsUnslugged} crumbSeparator=" » " />
          </Box>
          }
          <SimpleGrid columns={[1, 1, 2]} gap="30px">
            {data.allWpPost.nodes.map((page) => (
              <Box>
                <Box
                  key={page.link}
                  boxShadow={themeVars.boxShadow}
                  borderRadius={themeVars.borderRadius}
                  overflow="hidden"
                  height="100%"
                  display="flex"
                  flexDirection="column"
                >
                  <Link to={`${normalizePath(page.uri)}#newsStart`} position="relative">
                    <Box
                      padding="30px"
                      color="white"
                      background={!!page?.featuredImage?.node?.mediaDetails?.sizes[1]?.sourceUrl
                        ? 
                          `url(${page.featuredImage.node.mediaDetails.sizes[1].sourceUrl.replace(/files.mtasolutions.com/g, 'mta-solutions.mo.cloudinary.net')}), ${themeVars.gradients.blueMint}`
                        : 
                          `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 500 323.5' style='enable-background:new 0 0 500 323.5' xml:space='preserve'%3E%3Cstyle%3E .st0%7Bfill:%23fff%7D %3C/style%3E%3Cg id='XMLID_5_'%3E%3Cpath id='XMLID_19_' class='st0' d='M126.3 105.6s99-71.4 192.5-71.4 80.9 80.7 20.8 138.6c-59 56.8-154.5 82.4-202.8 82.4-60.9 0-105.3-29.1-75.3-87.1h-.8S34 200 34 235.4s38 47.9 69.5 47.9c44.9 0 99.7-16.7 160.2-50 28.4-15.6 56.3-34.1 80.8-57.8 24.5-23.7 44.3-49.5 55-73.3 22.6-50.1 9.2-92-54.8-92-44.2 0-97.8 20.9-140.3 43.8-27.8 15-54.3 32.2-79.2 51.6h1.1z'/%3E%3Cpath id='XMLID_18_' class='st0' d='M59.8 164.8c-74.9 85.4-66.3 150.7 11.9 150.7 58.1 0 136-34.8 187-67.8 43.9-28.4 72.2-55.8 72.2-55.8h-1.1s-19.5 17.8-51.1 37.1c-26.9 16.3-55.5 29.7-85.2 40.1-29.1 10.2-60.2 18.5-93 18.5-62.9 0-77.6-36.3-68.8-69.2 7.5-28.1 29.1-53.6 29.1-53.6h-1z'/%3E%3Cpath id='XMLID_17_' class='st0' d='M66.7 100.4v91.4h32.4V142l27.9 49.8h23.8l27.9-50v50h32.1v-91.4h-45.1l-26.9 50-26.9-50z'/%3E%3Cpath id='XMLID_16_' class='st0' d='M345.7 100.4H240.4v28.4h36v63h33.2v-63h36.1z'/%3E%3Cpath id='XMLID_11_' class='st0' d='M458.3 191.8h36.9l-49.5-91.5h-37.2l-49.6 91.5h36.9l7.6-14.7h47.2l7.7 14.7zm-41.6-40.1 10.4-20 10.4 20h-20.8z'/%3E%3Cpath id='XMLID_10_' class='st0' d='M66.7 100.4v91.4h32.4V142l27.9 49.8h23.8l27.9-50v50h32.1v-91.4h-45.1l-26.9 50-26.9-50z'/%3E%3Cpath id='XMLID_9_' class='st0' d='M345.7 100.4H240.4v28.4h36v63h33.2v-63h36.1z'/%3E%3Cpath id='XMLID_6_' class='st0' d='M458.3 191.8h36.9l-49.5-91.5h-37.2l-49.6 91.5h36.9l7.6-14.7h47.2l7.7 14.7zm-41.6-40.1 10.4-20 10.4 20h-20.8z'/%3E%3C/g%3E%3C/svg%3E"), ${themeVars.gradients.blueMint}`
                      }
                      backgroundSize={!!page?.featuredImage?.node?.mediaDetails?.sizes[1]?.sourceUrl
                        ? "cover"
                        : "45%, cover"
                      }
                      backgroundRepeat="no-repeat"
                      backgroundPosition="center"
                      minHeight="300px"
                    />
                  </Link>

                  <Box
                    padding="30px"
                    position="relative"
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    color={themeVars.colors.blue}
                  >
                    <Box>
                      <Link to={`${normalizePath(page.uri)}#newsStart`} position="relative">
                        <Heading as="h5" mb="10px!important" color={themeVars.colors.blue}>
                          {page.title}
                        </Heading>
                      </Link>

                      {!page.newExcerpt.hidePublishDate &&
                        <Box as="p" mb="25px">
                          {page.date}
                        </Box>
                      }

                      {page.newExcerpt !== null &&
                        <Box as="p" mb="30px" position="relative">
                          {parse(page.newExcerpt.newExcerpt)}...
                        </Box>
                      }
                    </Box>
                    <Box textAlign="right" position="relative">
                      <Link to={`${normalizePath(page.uri)}#newsStart`} className="button">
                        Read more
                      </Link>
                    </Box>
                  </Box>

                </Box>
              </Box>
            ))}
          </SimpleGrid>
        </Box>

        {pageContext && pageContext.totalPages > 1 && (
          <Box className="container" maxWidth="696px" mt="100px" mb="100px" display="flex" justifyContent="center">
            <ReactPaginate
              previousLabel={
                pageContext?.page !== 1 && <Button color="white">«</Button>
              }
              nextLabel={
                pageContext?.totalPages !== pageContext.page && (
                  <Button color="white">»</Button>
                )
              }
              onPageChange={({ selected }) => {
                const page = selected + 1
                const path = page === 1 ? `/news-alerts/` : `/news-alerts/${page}/`
                navigate(path)
              }}
              disableInitialCallback
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageContext.totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              initialPage={pageContext.page - 1}
            />
          </Box>
        )}
      </Layout>
    </ChakraProvider>

  )
}

export const query = graphql`
  fragment Thumbnail on File {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED, quality: 85, placeholder: NONE)
    }
  }
  query NewsPage($offset: Int!, $perPage: Int!) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: { nodeType: { in: ["Post", "Page", "Alot"] } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        uri
        title
        date(formatString: "MMMM Do, YYYY")
        excerpt
        newExcerpt {
          newExcerpt
          hidePublishDate
        }
        featuredImage {
          node {
            altText
            localFile {
              ext
              url
              ...Thumbnail
            }
            mediaDetails {
              sizes {
                sourceUrl
              }
            }
          }
        }
      }
    }
    wp {
      mtaGeneralSettings {
        blogHeroContent {
          heroSlides {
            heroSlideContentH1
            heroSlideContentH4
            heroSlideLink {
              url
              title
              target
            }
            secondaryButton {
              target
              title
              url
            }
            heroSlideIllustrationSelect
            heroSlideImage {
              altText
              localFile {
                url
                ext
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, quality: 85, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }
`
